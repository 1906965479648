
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StickyBottomOffer',
  props: {
    nextDisabled: {
      type: Boolean,
      default: false,
    },
    nextLoading: {
      type: Boolean,
      default: false,
    },
    nextLabel: {
      type: String,
      default: null,
    },
  },
  methods: {
    nextClick() {
      this.$emit('next-click')
    },
    prevClick() {
      // send click on prev to OfferTitle component
      this.$nuxt.$emit('offer-prev')
    },
  },
})
